@import '../../styles/variables.scss';

.cart-item {
    position: relative;
    padding: 10px;
    background-color: rgba(65, 94, 67, 0.031372549);
    border-color: $color-light-grey;
    border-radius: 6px;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
    &--order {
        padding: 30px;
    }
    &:not(:last-child) {
        margin-bottom: 20px;
    }
    &__inner {
        display: flex;
        column-gap: 20px;
        align-items: center;
        @media screen and (max-width: 470px) {
            flex-direction: column;
        }
        &--order {
            justify-content: space-between;
            @media screen and (max-width: 700px) {
                flex-direction: column;
                align-items:normal;
            }
        }
    }
    &__left {
        display: flex;
        align-items: center;
        @media screen and (max-width: 700px) {
            margin-bottom: 20px;
        }
    }
    &__right {
        display: flex;
        align-items: center;
        gap: 30px;
        @media screen and (max-width: 700px) {
            gap: 0;
            justify-content: space-between;
        }
    }
    &__img-container {
        width: 100px;
        overflow: hidden;
        border-radius: 6px;
        &--order {
            min-width: 100px;
            max-width: 100px;
            overflow: hidden;
            border-radius: 6px;
            margin-right: 20px;
        }
    }
    &__img {
        width: auto;
        height: 80px;
        margin: 0 auto;
    }
    &__title {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 10px;
        max-width: 200px;
        &--order {
            max-width: 100%;
        }
        @media screen and (max-width: 470px) {
            max-width: 100%;
            font-size: 16px;
            text-align: center;
        }
    }
    &__size {
        font-size: 14px;
        margin-bottom: 10px;
        @media screen and (max-width: 470px) {
            text-align: center;
        }
    }
    &__content {
        width: 260px;
        @media screen and (max-width: 550px) {
            width: 100%;
        }
        @media screen and (max-width: 470px) {
            margin-top: 20px;
        }
    }
    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__counter {
        width: 135px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $color-green;
        background-color: #bae1b6;
        border-radius: 6px;
        padding: 8px;
    }
    &__count-btn {
        padding: 5px 10px;
        font-size: 18px;
        border-radius: 6px;
        transition: background-color 0.2s linear;
        &--disable {
            cursor: default;
            color: rgba($color-green, 0.7);
            &:hover {
                background-color: #bae1b6 !important;
            }
        }
        @media screen and (min-width: 900px) {
            &:hover {
                background-color: $color-white;
            }
        }
    }
    &__quanity {
        outline: none;
        border: none;
        width: 60px;
        text-align: center;
        font-size: 16px;
        overflow: hidden;
        background-color: inherit;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
    }
    &__summ {
        width: 100px;
        margin-left: 20px;
        font-size: 16px;
        font-weight: 700;
        text-align: right;
        border-radius: 6px;
        color: $color-green;
        & strong {
            color: $color-red;
        }
    }
    &__delete-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 16px;
        border-radius: 6px;
        @media screen and (max-width: 450px) {
            top: 5px;
            right: 5px;
        }
    }
    &__trash {
        width: 25px;
        height: 25px;
        fill: $color-black;
        transition: fill 0.2s linear;
        &:hover {
            fill: $color-red;
        }
        @media screen and (max-width: 450px) {
            width: 22px;
            height: 22px;
        }
    }
}